<template>
  <div>
    <div v-if="!loaded" class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <div>
            <div class="b-skeleton is-centered is-animated">
              <div id="pfp-skel" class="b-skeleton-item is-rounded" style="height: 128px; width: 128px; border-radius: 50%;">
              </div>
            </div>
          </div>
          <div class="b-skeleton is-centered is-animated">
            <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 30px; width: 30%;margin-bottom: 5px;margin-top:10px;"></div>
          </div>
          <div class="b-skeleton is-centered is-animated">
            <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 30px; width: 35%;margin-bottom: 10px;margin-top:0px;"></div>
          </div>
          <div style="width: 50%;margin: 0 auto;margin-top:5px;margin-bottom: 10px;">
            <b-progress size="is-medium" :value=100>
            </b-progress>
          </div>
          <div class="b-skeleton is-centered is-animated">
            <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 30px; width: 30%;margin-bottom: 10px;margin-top:5px;"></div>
          </div>
        </div>
      </section>
      <section class="section" style="padding: 1.5rem 1.5rem 3rem">
        <div class="columns is-centered">
          <div class="column is-centered is-three-quarters has-shadow queuerows">
            <div class="columns is-multiline" v-for="(number, index) in [0,1,2,3]" :key="number" :id="index">
              <div class="column is-4" v-for="(number, index) in [0,1,2]" :key="number" :id="index">
                <div class="card has-shadow twitch-card">
                  <div class="card-content" style="height: 100%;">
                    <div class="media">
                      <div class="media-content" style="overflow-x: unset;">
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 30px; width: 43%;margin-bottom: 5px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 32%;margin: 0px;padding:0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 27%;margin: 0px;padding:0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 30%;margin: 0px;padding:0px;"></div>
                        </div>
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 15px; width: 20%;margin: 0px;padding:0px;"></div>
                        </div>
                      </div>
                      <div class="media-right">
                        <div class="b-skeleton is-animated">
                          <div id="leaderTextSkel" class="b-skeleton-item is-rounded" style="height: 20px; width: 30px;margin: 0px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <div v-if="guild.icon">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-if="!guild.icon.includes('a_')" :class="{'static': guild.icon.includes('a_')}" :src="'https://cdn.discordapp.com/icons/' + guild.id + '/' + guild.icon + '.' + ((guild.icon || '').startsWith('a_') ? 'gif' : 'webp') + '?size=128'" :alt="guild.name">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-else :src="'https://cdn.discordapp.com/icons/' + guild.id + '/' + guild.icon + '.gif?size=128'" :alt="guild.name">
          </div>
          <h1 class="title noselect">{{ $store.getters.admin.streamerMode ? '????' : guild.name }}'s Music Queue</h1>
          <h1 v-if="Object.keys(queue.currentlyPlaying).length > 0" style="margin-bottom:5px" class="subtitle is-4 has-text-white noselect">{{ queue.currentlyPlaying.title }} by {{ queue.currentlyPlaying.raw.info.author }}</h1>
          <h1 v-else style="margin-bottom:5px" class="subtitle is-4 has-text-white noselect">Currently No Music Is Playing</h1>
          <div style="width: 50%;margin: 0 auto;" v-if="this.queue.player && Object.keys(queue.currentlyPlaying).length > 0">
            <b-progress size="is-medium" :value="(this.queue.player.state.position / this.queue.currentlyPlaying.raw.info.length) * 100" show-value>
              {{ ((this.queue.player.state.position / this.queue.currentlyPlaying.raw.info.length) * 100).toFixed(1) }}% {{ this.msToTime(queue.player.state.position ? queue.player.state.position : 0) }}/{{ this.msToTime(queue.currentlyPlaying.raw.info.length ? queue.currentlyPlaying.raw.info.length : 0) }}
            </b-progress>
          </div>
          <h1 style="margin-top:5px" class="subtitle is-4 has-text-white noselect" v-if="Object.keys(queue.currentlyPlaying).length > 0 && this.queue.player">{{ this.queue.player.extra.disableskip ? 'Skip Disabled' : (this.queue.skips.length + ' Skip Request') }} •  <b-icon :class="this.queue.player.paused ? 'has-text-warning' : 'has-text-success'" :icon="this.queue.player.paused ? 'pause' : 'play'" size="is-small" style="margin-right: 5px;"></b-icon> <b-icon title="Shuffle Activated" v-if="this.queue.player.extra.shuffle" icon="random" size="is-small" class="has-text-white"></b-icon> <b-icon title="Repeat Activated" class="has-text-white" v-if="this.queue.player.extra.repeat" icon="redo" size="is-small"></b-icon> <b-icon class="has-text-white" title="Debug Activated" v-if="this.queue.player.extra.debug" icon="bug" size="is-small"></b-icon> • {{ this.queue.player.state.volume }}% Volume</h1>
        </div>
      </section>
      <section class="section" style="padding: 1.5rem 1.5rem 3rem">
        <div class="columns is-centered">
          <div class="column is-centered is-three-quarters has-shadow queuerows">
            <h1 v-if="this.queue.queue.length <= 0" class="title is-2" style="text-align: center;">Start adding songs to show a queue!</h1>
            <img v-if="this.queue.queue.length <= 0" src='https://cdn.gracebot.net/grace/musicnotes.svg' style="height: 250px;padding-bottom: 10px;display: block;margin-left: auto;margin-right: auto;">
            <div v-else class="columns is-multiline" v-for="(chunk, index) in this.chunkArray(this.queue.queue, 3)" :key="index">
              <div class="column is-4" v-for="song in chunk" :key="song.uuid" :id="song.uuid">
                <div class="card has-shadow twitch-card">
                  <button class="delete twitch-delete" v-if="(userAcct ? (userAcct.guild ? userAcct.guild.permissionsArray.includes('MANAGE_MESSAGES') : false) : false)" @click="deleteSong($event, song)"></button>
                  <div class="card-content" style="height: 100%;">
                    <div class="media">
                      <div class="media-content" style="overflow-x: unset;">
                        <router-link v-if="song.data.type === 'spotify'" tag="p" :to="{ path: '/redirect/?to=' + song.data.extra.url }" class="title is-4 twitch-streamer-name">{{ song.data.info.title }}</router-link>
                        <router-link v-else tag="p" :to="{ path: '/redirect/?to=' + song.data.info.uri }" class="title is-4 twitch-streamer-name">{{ song.data.info.title }}</router-link>
                        <p v-if="song.data.type === 'spotify'" class="is-6 has-text-grey-light">Song By: {{ song.data.extra.artists.map(g => g.name).join(', ') }}</p>
                        <p v-else class="is-6 has-text-grey-light">Song By: {{ song.data.info.author }}</p>
                        <p class="is-6 has-text-grey-light">Song Length: {{ msToTime(song.data.info.length ? song.data.info.length : 0) }}</p>
                        <p class="is-6 has-text-grey-light">Requested By: {{ song.data.user.tag }}</p>
                        <b-icon v-if="['spotify', 'youtube', 'twitch', 'soundcloud'].includes(song.data.type)" class="logobtn" pack="fab" size="is-medium" :type="{'is-success': song.data.type === 'spotify', 'is-danger': song.data.type === 'youtube', 'is-primary': song.data.type === 'twitch', 'is-warning': song.data.type === 'soundcloud'}" :icon="song.data.type"></b-icon>
                        <p v-else class="is-6 has-text-grey-light">Type: {{ song.data.type.charAt(0).toUpperCase() + song.data.type.slice(1) }}</p>
                      </div>
                      <div class="media-right">
                        <h1 v-if="song.rank === 1" class="title is-2 mainTextColor">#1</h1>
                        <h1 v-else-if="song.rank === 2" class="title is-3" style="color: grey;">#2</h1>
                        <h1 v-else-if="song.rank === 3" class="title is-4" style="color: #cd7f32;">#3</h1>
                        <h1 v-else class="title is-5">#{{ song.rank.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-pagination style="margin-bottom: 0px;"
              v-if="queue.player"
              :total="totalAmount"
              :range-before="3"
              :range-after="1"
              :per-page="12"
              v-model="page"
              :icon-prev="'chevron-left'"
              :icon-next="'chevron-right'"
              :order="'is-centered'"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              @change="this.start">
            </b-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'queue',
    components: {},
    data () {
      return {
        loaded: false,
        queue: null,
        guild: null,
        sync: null,
        page: 1,
        totalAmount: 0,
        userAcct: null,
        updateTime: 15,
        abbrNumber: n => {
          if (n < 1e3) return n
          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
          if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
        },
        chunkArray: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            const myChunk = myArray.slice(index, index + chunksize)
            tempArray.push(myChunk)
          }
          return tempArray
        },
        chunkArray2: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            let myChunk = myArray.slice(index, index + chunksize)
            if (myChunk.length === 1) {
              const extra = { ...myArray.slice(index, index + chunksize)[0], sameas1: true }
              myChunk = [...myArray.slice(index, index + chunksize), extra]
            }
            tempArray.push(myChunk)
          }
          return tempArray
        }

      }
    },
    watch: {},
    async beforeDestroy () {
      clearInterval(this.sync)
    },
    async created () {
      if (this.$route.query.page && !isNaN(this.$route.query.page)) this.page = Number(this.$route.query.page) <= 0 ? 1 : Number(this.$route.query.page) || 1
      this.start()
      this.sync = setInterval(this.start, (this.updateTime * 1000))
    },
    methods: {
      start () {
        this.$store.dispatch('getApi', { path: `plugin/music/${this.$route.params.id}/queue`, params: { page: (this.page || 1) - 1 } }).then(async (result) => {
          this.queue = result.data.result.data
          this.totalAmount = result.data.result.data.pageInfo?.total || 0
          this.userAcct = result.data.user
          this.guild = result.data.guild
          this.loaded = true
        }).catch((error) => {
          console.log(error, 'error')
          if (Number(error.response.status) === 404) return this.$router.push('/404?reason=Guild not found')
          if (Number(error.response.status) === 500) return this.$router.push('/500?reason=' + error)
          return this.$router.push('/404?reason=unknown')
        })
      },
      msToTime (duration) {
        let seconds = Math.floor((duration / 1000) % 60) ? Math.floor((duration / 1000) % 60) : 0
        let minutes = Math.floor((duration / (1000 * 60)) % 60) ? Math.floor((duration / (1000 * 60)) % 60) : 0
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24) ? Math.floor((duration / (1000 * 60 * 60)) % 24) : 0
        hours = (hours < 10) ? '0' + hours : hours
        minutes = (minutes < 10) ? '0' + minutes : minutes
        seconds = (seconds < 10) ? '0' + seconds : seconds
        // milliseconds = (milliseconds < 10) ? '0' + milliseconds : milliseconds
        return ([(hours === '00' ? null : hours), (minutes === '00' ? null : minutes), (seconds === '00' ? null : seconds)].filter(r => r).length <= 0 ? '00:00' : [(hours === '00' ? null : hours), (minutes === '00' ? null : minutes), (seconds === '00' ? null : seconds)].filter(r => r).join(':'))
      },
      deleteSong (el, song) {
        var _this = this
        el = this.$(this.$(el.target)[0].parentElement)
        el.addClass('is-disabled', true)
        this.$swal.fire({
          // confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          title: 'Are you sure?',
          text: 'You won\'t be able to undo this!',
          footer: `Pressing will delete ${song.data.info.title || 'Unknown'}!`,
          icon: 'warning',
          showLoaderOnConfirm: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            const snackbar = this.$buefy.snackbar.open({
              message: 'Deleteing Song, please wait...',
              type: 'is-warning',
              position: 'is-bottom-right',
              indefinite: true,
              actionText: null
            })
            this.$store.dispatch('deleteApi', { path: `plugin/music/${this.$route.params.id}/queue/${song.uuid}`, body: { id: song.uuid /* Just incase put id in body if we choose to change it we can */ }, headers: {}, params: {} }).then(async (result) => {
              snackbar.close()
              this.$buefy.snackbar.open({
                message: 'Song deleted!',
                type: 'is-success',
                position: 'is-bottom-right'
              })
              el.removeClass('is-disabled', true)
              await this.$store.dispatch('sleep', 2000)
              this.loaded = this.queue = this.userAcct = null
              this.start()
            }).catch(async (error) => {
              console.log(error, 'Error')
              snackbar.close()
              this.$buefy.snackbar.open({
                message: 'Oh no! Some error happened! Try Reloading',
                type: 'is-danger',
                position: 'is-bottom-right'
              })
              el.removeClass('is-disabled', true)
              await this.$store.dispatch('sleep', 2000)
              this.loaded = this.queue = this.userAcct = null
              this.start()
            })
          } else {
            el.removeClass('is-disabled', true)
          }
        })
      }
    }
  }
</script>

<style>
  .progress::-webkit-progress-bar {
    background: var(--main-color-bg-3) !important;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .progress::-webkit-progress-value {
    background: var(--is-premium-1);
  }
  .carousel .carousel-indicator.is-inside.is-bottom {
    bottom: unset !important;
  }
</style>

<style scoped>

.logobtn {
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 24px;
}

/* @media only screen and (max-width: 768px) {
  .media-right {
    display: none;
  }
} */

.twitch-card {
  height: 100% !important;
  border-radius: 10px !important;
  background: var(--main-color-bg-2) !important;
  /* cursor: pointer; */
  transition: .3s;
  height: 100%;
  border-radius: 10px;
  background: var(--main-color-bg-2);
}

/* .twitch-card:hover {
  filter: brightness(.6);
  -webkit-filter: brightness(.6);
  transform: scale(1.02);
  transition: all .3s ease-in-out;
} */

.twitch-delete {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  visibility: hidden;
}

.twitch-card:hover .twitch-delete {
  visibility: visible !important;
}

.modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
  background-color: #f04747 !important;
}

.to-twitch {
  cursor: pointer;
  transition: .3s;
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 100px;
  border-color: var(--main-color-bg-3);
  border-width: 4px;
  border-radius: 100px;
  border-style: solid;
}

.to-twitch:hover {
  filter: brightness(.6);
  -webkit-filter: brightness(.6);
  transform: scale(1.02);
  transition: all .3s ease-in-out;
}

.twitch-streamer-name {
  margin-bottom: 0px;
  width: fit-content;
  cursor:pointer;
  transition: .3s;
}

.twitch-streamer-name:hover {
  color: #7957d5 !important;
}

  section {
    height: 100% !important;
  }

  .card-content {
    height: 100%;
  }

  .queuerows {
    border-radius: 10px;
    background: var(--main-color-bg-2);
    min-height: 200px;
  }

  .userLeader {
    cursor: pointer;
    transition: .3s;
    height: 100%;
    border-radius: 10px;
    background: var(--main-color-bg-2);
  }

  .userLeader:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.02);
    transition: all .3s ease-in-out;
  }

  .userLeader-pfp {
    width: 80px;
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
  }

  #top-pfp {
    cursor: pointer;
    position: relative;
    padding-right: 125px;
    padding-bottom: 94px;
    z-index: 1;
  }

  #top-pfp-img {
    position: absolute;
    /* display: inline; */
    width: 128px;
    transition: .3s;
  }

  #top-pfp-img:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.02);
    transition: all .3s ease-in-out;
  }

  #top-pfp-text {
    position: absolute;
    z-index:10;
    width:100%;
    bottom:0;
    text-align:center;
    margin-bottom: -73px;
  }

  @media only screen and (max-width: 768px) {
    /* #podium-box {
      display: list-item !important;
    }
    .step-container {
      display: list-item !important;
    } */
    #top-pfp-text {
      margin-bottom: -110px;
    }
    .crown {
      transform: scale(1) rotate(-27deg) !important;
      left: -16px !important;
      animation: pulseCrownMobile 2s infinite !important;
    }
  }

  .has-shadow, #pfp-skel, #leaderTextSkel, #userLeader-pfp-skel {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .has-shadow-1, #pfp-skel1 {
    box-shadow: 0 0.8rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .icon-img, #pfp-skel, #pfp-skel1 {
    /* float: left; */
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
  }
  .headerSection {
    width: 100%;
    height: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    position: relative;
  }

  .levelSep {
    width: 100%;
    height: 1px;
    margin: 10px 0 10px 0;
    background-color: var(--main-color-bg) !important;
  }

  #podium-box {
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
  }

  .podium-number {
    font-weight: bold;
    font-size: 4em;
    color: white;
  }

  .step-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 250px !important;
  }

  .crown {
    position: absolute;
    top: -55px;
    left: 66px;
    z-index: 2;
    transform: scale(1) rotate(29deg);
    width: 80px;
    animation: pulseCrown 2s infinite;
  }

  @keyframes pulseCrown {
    0% {
      transform: rotate(29deg) scale(0.95);
    }

    70% {
      transform: rotate(29deg) scale(1);
    }

    100% {
      transform: rotate(29deg) scale(0.95);
    }
  }

  @keyframes pulseCrownMobile {
    0% {
      transform: rotate(-27deg) scale(0.95);
    }

    70% {
      transform: rotate(-27deg) scale(1);
    }

    100% {
      transform: rotate(-27deg) scale(0.95);
    }
  }

  .step-container>div:first-child {
    margin-top: auto;
    text-align: center;
  }

  .step {
    text-align: center;
  }

  #first-step {
    height: 250px;
    background: var(--is-premium-1) /*-webkit-linear-gradient(258deg,#9656eb,#9656eb,#d64bea,#d64bea)*/ !important;
    border-radius: 10px;
  }

  #second-step {
    height: 200px;
    background: grey;
    border-radius: 10px;
  }

  #third-step {
    height: 175px;
    background: #cd7f32;
    border-radius: 10px;
  }

  #textTopTitleUser {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
